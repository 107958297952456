import React, { createElement } from "react";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
// svgs
import { ReactComponent as BusinessCardIcon } from "../svgs/businessCard.svg";
import { ReactComponent as JobIcon } from "../svgs/job.svg";
import { ReactComponent as LockIcon } from "../svgs/lock.svg";
import { ReactComponent as RocketIcon } from "../svgs/rocket.svg";

import { useAlert } from "../components/Alert";

const GET_PRODUCTS = gql`
  query products($latest: Boolean, $pageSize: Int, $page: Int) {
    products(latest: $latest, pageSize: $pageSize, page: $page) {
      contents {
        id
        images {
          location
        }
        name
        price
      }
    }
  }
`;

export default function HomePage() {
  return (
    <div>
      <HomeBanner />
      <HotProductList />
      <Advantage />
    </div>
  );
}

function HomeBanner() {
  return (
    <div>
      <img src={"/assets/images/Home/banner.jpg"} style={{ width: "100%" }} />
    </div>
  );
}

// title component

function Title({ title }) {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    title: {
      borderBottom: `5px solid ${theme.palette.primary.main} `,
      paddingBottom: "5px",
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" mb={4}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}
// product list
function HotProductList() {
  const Alert = useAlert();
  const theme = useTheme();
  const isPhone = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const useStyles = makeStyles({
    productListCard: {
      maxWidth: "100%",
    },
    media: {
      height: 300,
      width: "100%",
      objectFit: "fill",
    },
    set: {
      position: "absolute",
      background: theme.palette.primary.main,
      right: 0,
      width: "74px",
      height: "32px",
      textAlign: "center",
      lineHeight: "32px",
      borderRadius: "4px",
    },
    containerLoading: {
      height: "50vh",
      alignContent: "center",
    },
  });
  const classes = useStyles();

  const { data, loading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
      pageSize: 6,
      page: 1,
    },
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  return (
    <Box mb={12}>
      <Container maxWidth="lg">
        <Box my={8}>
          <Box>
            <Typography
              variant={isPhone ? "h5" : "h4"}
              style={{ color: theme.palette.text.primary, fontWeight: 700 }}
              align="center"
            >
              集所有玩家需求的商店
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography
              variant={isPhone ? "body1" : "h6"}
              style={{ color: "#9E9E9E", fontWeight: 700 }}
              align="center"
            >
              最多元的付款方式及最划算的價格，為你而生的線上火力超市
            </Typography>
          </Box>
        </Box>

        {/* product content */}
        <Box>
          <Title title="熱門商品" />
        </Box>
        {/* lists */}
        {loading ? (
          <Grid container justify="center" className={classes.containerLoading}>
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Box>
            {Boolean(data) && data.products.contents ? (
              <Grid container spacing={4}>
                {/* list.map */}
                {data.products.contents.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      justifyContent="center"
                      xs={isPhone ? 12 : 6}
                      sm={6}
                      md={4}
                      lg={3}
                    >
                      <Card className={classes.productListCard}>
                        <CardActionArea
                          onClick={() => history.push(`/product/${item.id}`)}
                        >
                          <Box display="flex" justifyConter="center">
                            <img
                              src={item.images && item.images[0]?.location}
                              className={classes.media}
                            />
                          </Box>
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              component="h2"
                              style={{ fontWeight: 700 }}
                            >
                              {item.name}
                            </Typography>
                          </CardContent>
                          <CardActions style={{ justifyContent: "flex-end" }}>
                            <Typography
                              variant="h6"
                              color="primary"
                              style={{ fontWeight: 700 }}
                            >
                              $ {item.price}
                            </Typography>
                          </CardActions>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container justify="center">
                <Typography
                  variant="h6"
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  暫無商品
                </Typography>
              </Grid>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}

function Advantage() {
  const theme = useTheme();
  const isPhone = useMediaQuery("(max-width:600px)");
  const useStyles = makeStyles({
    listSvgBox: {
      width: "120px",
      height: "120px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      position: "relative",
      marginBottom: "1em",
    },
    listSvg: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
  });
  const classes = useStyles();

  const advantageList = [
    {
      label: "付款後一日內立即發送",
      svg: RocketIcon,
    },
    {
      label: "24/7 客服團隊全年無休",
      svg: JobIcon,
    },
    {
      label: "採用 SSL 安全付款",
      svg: LockIcon,
    },
    {
      label: "多元付款方式",
      svg: BusinessCardIcon,
    },
  ];

  return (
    <div>
      <Container maxWidth="lg">
        <Title title="我們的優勢" />

        <Box my={6}>
          <Grid container spacing={2}>
            {advantageList.map((item) => {
              return (
                <Grid
                  xs={6}
                  sm={3}
                  container
                  item
                  direction="column"
                  alignItems="center"
                >
                  <Box className={classes.listSvgBox}>
                    {/* <RocketIcon className={classes.listSvg}/> */}
                    <Box className={classes.listSvg}>
                      {createElement(item.svg)}
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant={isPhone ? "body2" : "body1"}>
                      <b>{item.label}</b>
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
