import React, { createContext, useContext, useReducer } from "react";

const initFunction = {
  setMemberToken,
  getMemberToken,
  getCartId,
  removeCartId,
};

const LocalStorageContext = createContext(initFunction);

export function useLocalStorage() {
  const { forceUpdate } = useContext(LocalStorageContext);
  return { forceUpdate, ...initFunction };
}

export function LocalStorageContextProvider({ children }) {
  const [ignored, forceUpdate] = useReducer((e) => !e, false);
  return (
    <LocalStorageContext.Provider value={{ forceUpdate }}>
      {children}
    </LocalStorageContext.Provider>
  );
}

//Member
function setMemberToken(token) {
  return localStorage.setItem("@yangyang3c-webToken", token);
}
function getMemberToken() {
  return localStorage.getItem("@yangyang3c-webToken");
}

//Cart
function getCartId() {
  return localStorage.getItem("@yangyang3c-webCartId");
}
function removeCartId() {
  return localStorage.removeItem("@yangyang3c-webCartId");
}
