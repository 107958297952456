import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core'

export default function FQAPage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        backgroundColor: 'white',
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #FFB506',
          margin:'50px auto'
        }
    },
    }));

    const faqList = [
      {
        title:'Q: 如何聯繫客服人員?',
        answer:'請在上班時間來電(02)7753-3533'
      },
      {
        title:'Q: 網站採用哪個國家的幣值?',
        answer:'新台幣'
      },
      {
        title:'Q: 如何找到商品規格對照表?',
        answer:'要確認你原本機種能否適用可洽本公司客服專線'
      },
      {
        title:'Q: 可以跨海購物嗎?',
        answer:'目前沒有零售海外 ; 因為產品稅率不一致 , 若要海外大量訂購歡迎來電客服+886 2 7753-3533'
      },
      {
        title:'Q: 大量可以更優惠嗎?',
        answer:'請來電客服討論'
      },
      {
        title:'Q:如何計算運費?',
        answer:'本網站消費10,000以下單一金額500元整; 10,000以上專人免運送達.'
      },
      {
        title:'Q:何時能收到我購買的商品?',
        answer:'正常情況是在訂購後約4個工作天可送達.'
      },
      {
        title:'Q:當宅配抵達時，如果我剛好無法收件該如何處理?',
        answer:'請聯繫客服更改收件時間.'
      },
      {
        title:'Q:如果商品未在網站公告時間內準時送達，後續該如何處理 ?',
        answer:'請聯繫客服更反應.'
      },
    ];

  const classes = useStyles();
    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11}  style={{ marginBottom: "50px"}}>
          <Box display="flex" justifyContent="center" >
            <Typography variant="h4">FAQ</Typography>
          </Box>
          {
            faqList.map((item)=>{
              return(
              <Grid>
                <Typography variant="h6" style={{fontWeight:'bold'}}>{item.title}</Typography>
                <Typography variant="h6" style={{ marginBottom: "30px"}}>{item.answer}</Typography>
              </Grid>
              )
            })
          }
          </Grid>
      </Grid>
    );
}