import React from "react";
import Route from "./Route";
import ThemeProvider from "./ThemeProvider.js";
import { LocalStorageContextProvider } from "./components/LocalStorageContext";
import { AlertProvider } from "./components/Alert";

export default function App() {
  return (
    <ThemeProvider>
      <LocalStorageContextProvider>
        <AlertProvider>
          <Route />
        </AlertProvider>
      </LocalStorageContextProvider>
    </ThemeProvider>
  );
}
