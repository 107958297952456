import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  TextareaAutosize,
  Button,
  Box,
} from "@material-ui/core";

export default function ContactUsPage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "auto",
      backgroundColor: "white",
      textAlign: "center",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #FFB506",
        margin: "50px auto",
      },
    },
  }));
  const classes = useStyles();

  const contactUs = [
    "客服時間 : 星期一 ～ 星期六，10:00AM～19:00 PM",
    "客服專線：(02)7753-3533",

    "公司地址：台北市信義區信義路五段7號37樓",

    "總公司專線(02)7753-3533",

    "若需即時回覆或互動如下 :",
    "大宗採購/稀缺商品查詢/團購主協助",
    "歡迎加我們的LINE ID:@728xabci詢問~",
    "(掃描以下圖)",
    "值班人員會依據您的需求分配接洽! 謝謝",
  ];
  return (
    <Grid
      className={classes.box}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={5} md={5}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">聯絡我們</Typography>
        </Box>
        {contactUs.map((item) => {
          return (
            <Typography variant="body1" gutterBottom>
              {item}
            </Typography>
          );
        })}
        <Box>
          <img src='/assets/images/qrcode.jpg' alt='QRcode'/>
        </Box>
        <Grid item xs={11} style={{ margin: "50px auto 30px" }}>
          <TextareaAutosize
            minRows={10}
            style={{ width: "100%", resize: "none" }}
          />
        </Grid>
        <Grid item xs={11} style={{ margin: "0px auto 150px" }}>
          <Button color="primary" variant="contained">
            送出
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
