import React, { useState, useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  useMediaQuery,
  Container,
  Grid,
  TextField,
  ButtonBase,
  Typography,
  makeStyles,
  useTheme,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@material-ui/core";
//   icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import MinerIcon from "@material-ui/icons/Remove";

import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import BuyFloatingLayer from "../components/BuyFloatingLayer";
import { useLocalStorage } from "../components/LocalStorageContext";

const GET_PRODUCT = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      images {
        location
      }
      name
      price
      description
      category {
        id
        name
      }
      contents {
        id
        type
        body
      }
    }
  }
`;

const ADD_ITEMTOCART = gql`
  mutation addItemToCart($id: Int!, $cartItemInput: CartItemInput!) {
    addItemToCart(id: $id, cartItemInput: $cartItemInput)
  }
`;

const CREATE_ORDER = gql`
  mutation createOrder($orderInput: OrderInput!) {
    createOrder(orderInput: $orderInput) {
      id
      paymentUrl
    }
  }
`;

export default function ProductPage() {
  const Alert = useAlert();
  const useStyles = makeStyles({
    containerLoading: {
      height: "50vh",
      alignContent: "center",
    },
  });
  const classes = useStyles();
  let { id } = useParams();

  const [getProduct, { data, loading }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getProduct({ variables: { id: Number(id) } });
    }
  }, [id]);

  if (loading) {
    return (
      <Grid container justify="center" className={classes.containerLoading}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <div>
      <BreadcrumbsBox data={data} />
      <Product data={data} />
      <ProductInfo data={data} />
    </div>
  );
}
// ANCHOR Product + carousel
function Product({ data }) {
  const Alert = useAlert();
  const LocalStorage = useLocalStorage();
  const [imageIndex, setImageIndex] = useState(0);
  const isTablet = useMediaQuery("(max-width:960px)");
  const isPhone = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    countBox: {
      "& .MuiInputBase-root": {
        padding: "0",
      },
      "& .MuiInputBase-input": {
        textAlign: "center",
        margin: 0 + " !important",
      },
    },
    textfield: {
      "& .MuiOutlinedInput-inputMarginDense": {
        padding: "0",
      },
      "& .MuiOutlinedInput-input": {
        padding: "0",
      },
    },
    countBtn: {
      cursor: "pointer",
      color: "#fff",
      backgroundColor: theme.palette.grey[400],
      border: "none",
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    cssOutlinedInput: {
      "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline":
        {
          border: "2px solid",
          borderColor: "#bdbdbd",
        },
      "&$cssFocused $notchedOutline": {
        // border: '1px solid',
        borderColor: "#bdbdbd",
      },
    },
    notchedOutline: {},
    cssFocused: {},
    set: {
      position: "absolute",
      background: theme.palette.primary.main,
      left: 40,
      zIndex: 1,
      width: "74px",
      height: "32px",
      textAlign: "center",
      lineHeight: "32px",
      borderRadius: "4px",
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  function _arrowNext() {
    if (imageIndex < data?.product.images.length - 1) {
      setImageIndex(imageIndex + 1);
    } else if (imageIndex === data?.product.images.length - 1) {
      setImageIndex(0);
    }
  }

  function _arrowPrev() {
    if (imageIndex > 0) {
      setImageIndex(imageIndex - 1);
    } else if (imageIndex === 0) {
      setImageIndex(data?.product.images.length - 1);
    }
  }

  // countBox
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const paymentMethodRef = useRef();

  function _addItemToCart() {
    addItemToCart({
      variables: {
        id: Number(LocalStorage.getCartId()),
        cartItemInput: {
          productId: data?.product.id,
          quantity: count,
        },
      },
    });
  }

  const [addItemToCart, { loading: addItemToCartLoading }] = useMutation(
    ADD_ITEMTOCART,
    {
      onCompleted({ addItemToCart }) {
        if (addItemToCart) {
          return Alert.notify("加入購物車成功！");
        } else {
          return Alert.notify("加入購物車失敗，請重新嘗試！");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  function _createOrder() {
    if (LocalStorage.getMemberToken()) {
      return setOpen(true);
    } else {
      return Alert.alert("通知", "請先登入才可購買！", [
        {
          text: "登入",
          onClick: () => history.push("/log-in"),
        },
        {
          text: "取消",
          type: "outlined",
        },
      ]);
    }
  }

  const [createOrder, { loading: createOrderLoading }] = useMutation(
    CREATE_ORDER,
    {
      onCompleted({ createOrder }) {
        if (createOrder) {
          if (createOrder.paymentUrl) {
            window.location = createOrder.paymentUrl;
          }
        } else {
          return Alert.notify("建立訂單失敗，請重新嘗試！");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  return (
    <Container maxWidth="xl">
      <LoadingModal loading={addItemToCartLoading || createOrderLoading} />
      <BuyFloatingLayer
        open={open}
        content={"運送資訊"}
        onChangeFloatingwindowClose={(value, paymentMethod, shipmentInput) => {
          setOpen(false);
          if (value === "mutation") {
            paymentMethodRef.current = paymentMethod;
            createOrder({
              variables: {
                orderInput: {
                  cartItemInput: {
                    productId: data?.product.id,
                    quantity: count,
                  },
                  shipmentInput,
                },
              },
            });
          }
        }}
      />
      <Box p={2} style={{ backgroundColor: "#fff" }}>
        <Grid container spacing={4} justifyContent="space-between">
          {/* carousel */}
          <Grid item xs={12} md={5}>
            <Carousel
              autoPlay
              showThumbs={false}
              infiniteLoop={true}
              showArrows={false}
              selectedItem={imageIndex}
              onChange={setImageIndex}
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                textAlign: isTablet ? "center" : "",
                position: "relative",
              }}
            >
              {data?.product.images.map((item, index) => (
                <>
                  {/* Prev箭頭 */}
                  <Box
                    position="absolute"
                    zIndex="2"
                    left={10}
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                  >
                    <Button onClick={_arrowPrev}>
                      <ArrowBackIosIcon style={{ color: "grey" }} />
                    </Button>
                  </Box>
                  {/* Next箭頭 */}
                  <Box
                    position="absolute"
                    zIndex="2"
                    right={10}
                    style={{ top: "50%", transform: "translateY(-50%)" }}
                  >
                    <Button onClick={_arrowNext}>
                      <ArrowForwardIosIcon style={{ color: "grey" }} />
                    </Button>
                  </Box>
                  <img
                    src={item.location}
                    key={index}
                    style={{
                      // 如果要完整顯示改成contain
                      objectFit: "contain",
                      width: "100%",
                      height: isPhone ? "300px" : isTablet ? "550px" : "500px",
                      borderRadius: "10px",
                      padding: "5px",
                    }}
                  />
                </>
              ))}
            </Carousel>
            <Box padding={1} mx="auto">
              <Grid
                container
                spacing={1}
                wrap={isPhone ? "nowrap" : ""}
                style={{ overflowX: isPhone ? "scroll" : "none" }}
              >
                {data?.product.images.map((item, index) => (
                  <Grid item>
                    <Box width="80px">
                      <ButtonBase
                        onClick={() => setImageIndex(index)}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={item.location}
                          key={index}
                          style={{
                            border:
                              imageIndex === index
                                ? "1px solid rgba(0,0,0,0.4) "
                                : "0px solid",
                            borderRadius: "3px",
                            width: "inherit",
                            height: "inherit",
                            objectFit: "cover",
                          }}
                        />
                      </ButtonBase>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          {/*ANCHOR  info */}
          <Grid
            item
            container
            xs={12}
            md={6}
            direction="column"
            justifyContent="space-between"
          >
            {/* name&price */}
            <Box>
              <Typography
                variant={isTablet ? "h6" : "h4"}
                gutterBottom
                color="textSecondary"
              >
                <b>{data?.product.name}</b>
              </Typography>
              <br />
              <Typography
                variant={isTablet ? "h6" : "h4"}
                color="primary"
                style={{ fontWeight: 700 }}
              >
                ${data?.product.price}
              </Typography>
              <Grid item xs={12} sm={10}>
                {data?.product.description?.split("\n").map((item) => (
                  <Typography variant="body1" style={{ color: "#9E9E9E" }}>
                    {item}
                  </Typography>
                ))}
              </Grid>
            </Box>
            {/* buyArea */}
            <Box p={isPhone ? 0 : 4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                  >
                    請選擇購買數量
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {/* countBox */}
                  <Box className={classes.countBox}>
                    <TextField
                      fullWidth
                      value={count}
                      className={classes.textfield}
                      margin="false"
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                        startAdornment: (
                          <Button
                            size="small"
                            className={classes.countBtn}
                            onClick={() =>
                              count <= 1 ? setCount(1) : setCount((e) => e - 1)
                            }
                          >
                            <MinerIcon position="start" />
                          </Button>
                        ),
                        endAdornment: (
                          <Button
                            size="small"
                            className={classes.countBtn}
                            onClick={() => setCount((e) => e + 1)}
                          >
                            <AddIcon position="end" />
                          </Button>
                        ),
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={_addItemToCart}
                  >
                    加入購物車
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={_createOrder}
                  >
                    馬上購買
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

// ANCHOR Product Info
function ProductInfo({ data }) {
  return (
    <Box my={6}>
      <Container maxWidth="xl">
        <Box px={2} py={4} style={{ backgroundColor: "#fff" }}>
          {data?.product.contents.map((item, index) => (
            <Grid container item key={index}>
              <DetailArea data={item} />
            </Grid>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
function DetailArea({ data }) {
  switch (data.type) {
    case "HEADING": //"標題"
      return <Typography variant="h6">{data.body}</Typography>;
    case "PARAGRAPH": //"段落"
      return (
        <Typography>
          {data.body.split("\n").map((line) => (
            <>
              {line}
              <br />
            </>
          ))}
        </Typography>
      );
    case "IMAGE": //"圖片"
      return (
        <img
          src={data.body}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            alignSelf: "flex-start",
          }}
        />
      );
    case "YOUTUBE_VIDEO": //"影片"
      let url = data.body;
      let embedUrl = "";
      if (url) {
        if (url.startsWith("http://") || url.startsWith("https://")) {
          if (url.search("embed") === -1) {
            if (url[8] === "w") {
              url = url.split("watch?v=")[1].split("&")[0];
            } else {
              url = url.split("youtu.be/")[1];
            }
          } else {
            embedUrl = data.body;
          }
        } else {
          return null;
        }
      }
      return (
        <iframe
          width="900"
          height="506"
          src={embedUrl || "https://www.youtube.com/embed/" + url}
          style={{ border: `0px` }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
        />
      );
    default:
      return null;
  }
}

// 麵包屑
function BreadcrumbsBox({ data }) {
  const history = useHistory();
  return (
    <Box py={4}>
      <Container maxWidth="xl">
        <Breadcrumbs>
          <Link
            color="inherit"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push("/product-list/" + data?.product.category.name)
            }
          >
            {data?.product.category.name}
          </Link>
          <Typography color="textPrimary">{data?.product.name}</Typography>
        </Breadcrumbs>
      </Container>
    </Box>
  );
}
