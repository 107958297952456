import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core'

export default function GuidePage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        backgroundColor: 'white',
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #FFB506',
          margin:'50px auto'
        }
    },
    }));
  const classes = useStyles();
    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h4" align="center">
              購物說明
            </Typography>
          </Box>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
            商品保固說明
          </Typography>
          <Typography variant="h6">
            凡於本站所購買的商品
            (不含附隨、無償方式所獲得之贈品)皆享有原廠提供的保固年限服務
            (福利品、出清品、拆封品則以頁面標註為主，商品保固若無額外標示
            保固說明則以商品本身剩餘保固時間為主，非依照購買時間起算)。
            <br />
            <br />
            於保固期限內，
            您可持購買憑證(可線上列印)與物品致電或Email於客戶服務窗口，
            我們將會有專人與您聯繫後續處理事宜(第一年免費收送服務，超過第
            一年則會酌收代送處理費用），其它原廠保固日期，則以原廠的保固服務規定辦理。
            <br />
            <br />
            保固期間內若因下列因素引起之損害，本公司將有權利酌收維修費用。
            在保固期內將產品寄回揚盛3C有限公司維修中心並不代表機器將獲得免
            費維修。在收到產品後，揚盛3C有限公司有權檢查購買證明（發票）及
            保固服務需求的有效性。如果保固期已失效，您的保固需求將被視為無效。
            <br />
            <br />
            <br />
            如果您的保固需求被視為無效，揚盛3C有限公司將出具一份維修價格表，
            您可選擇接受或拒絕。如果您選擇接受，揚盛3C有限公司將為您開立包含
            維修費用、料件費用以及其它在價格表上列出的項目費用的明細（發票）。
            <br />
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
            除外保固服務
          </Typography>
          <Typography variant="h6">
            揚盛3C有限公司不保證此產品不間斷或無錯誤運作。 <br />
            在保固期內可提供的任何技術或其他支援，如透過電話或Email諮詢操作方式、產品相關設定與資訊問題，均無須出示任何服務手冊便可獲得。{" "}
            <br />
            此保固服務僅包括在保固期內與正常使用狀況下所發生的故障或失常。{" "}
            <br />
            若因下列因素引起之損害，將不提供保固服務： <br /> <br />
            1.產品經非揚盛3C有限公司服務中心人員擅自變更、維修或加裝
            <br />
            2.任意變更、取消或移除產品之條碼、零件或配件
            <br />
            3.保固封條受損或更改、保固識別標籤撕毀或破壞至無法辨識
            <br />
            4.產品已報廢
            <br />
            5.由於產品的外觀改變（意外或其它）所造成的損壞，但對產品的作業過程及效能無影響。如生鏽、更改顏色、紋理裝飾、自然磨損及產品的逐步老化
            <br />
            6.由於戰爭、恐怖主義、火災、意外、自然災害、故意或無意的誤用、濫用、疏忽、非原廠維修，或非正常狀況下使用對產品所造成的損壞
            <br />
            7.使用非揚盛3C有限公司出售的零配件所引起的故障
            <br />
            8.由於不正確安裝或連接周邊裝置如印表機、光碟機、網路卡或 USB
            裝置所導致的故障
            <br />
            9.由於外接電源問題或意外導致的故障
            <br />
            10.未按照使用手冊操作方式、儲存設定或未在界定的適用範圍內操作造成損壞
            <br />
            11.由第三方軟體或電腦病毒所引起的問題或故障。以及在維修或更換過程中導致的軟體、資料訊息丟失
            <br />
            12.由於忘記或遺失安全密碼而導致的無法使用
            <br />
            13.由於有毒物質、疾病、蟲害或輻射等污染而導致的無法使用
            <br />
            14.欺詐、偷盜、無故消失等蓄意行為
            <br />
            15.由於安裝或使用解鎖裝置程式造成的無法使用或損毀，此程式集將會解鎖產品的啟動加載項，將導致您的保固無效
            <br /> <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
            ※出貨與配送時間
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "100px" }}>
            訂單出貨時間約 4 天，實際送達時間，依快遞公司配送狀況為主。
            <br />
            選購商品時，請務必謹慎確認訂單內容顏色和尺寸，再進行結帳。若完成訂單，便表示會員同意本規定。
            <br />
            ※收件人注意事項
            <br />
            收件資料請務必填寫：姓名、完整的收件地址。
            <br />
            收件人需以全名填寫，不可簡寫。
            <br />
            收件地址均需填寫正確郵遞區號。
            <br />
            <br />
            填單時請選擇正確的區域，並填寫正確地址、收件人完整資料，若資料錯誤造成無法寄送，運費將由買家自行負擔。
            <br />
          </Typography>
        </Grid>
      </Grid>
    );
}