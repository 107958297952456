import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  useTheme,
  Box,
  Container,
  useMediaQuery,
} from "@material-ui/core";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from "swiper/core";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

export default function AboutPage() {
  const isPhone = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles((theme) => ({
    box: {
      // minHeight: "100vh",
      backgroundColor: "white",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #FFB506",
        margin: "50px auto",
      },
    },
  }));
  const classes = useStyles();

  const aboutContent = [
    "嗨～我們是揚盛3C批發零售網，我們專注提供國際各大廠的SSD/RAM之批發零售。",
    "包括 威騰/ 西捷 /金士頓/威剛...等品牌。",
    "若你想找的產品或是型號並沒有出現在我們產品區。",
    "歡迎聯繫我們索取更多資訊。",
    "我們的三大優勢包括:",
    "1. 在這裡一件起批 ; 以優勢批發讓您省下庫存壓力 !",
    "2. 產品相關資訊也可由我們專員直接諮詢解答。",
    "省去研究時間,馬上替消費者解答問題 !",
    "3.所有商品提供黑貓、宅配通、郵局...等代寄貨服務，讓您達成『免包貨、免寄貨』，只要賣出就有利潤!",
    "歡迎團購/直播/批發/一頁商店賣家詢問了解!省去您刷簿子對帳的困擾 !!",
    "同業想叫貨、企業要大宗採購、團爸團媽想團購、直播主找商品、想兼職賣商品、歡迎請先聯絡我們客服諮詢! 謝謝您。",
  ];

  return (
    <Container maxWidth="lg">
      <Grid
        className={classes.box}
        container
        spacing={2}
        justifyContent="center"
      >
        <Grid item xs={11}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h4">關於我們</Typography>
            <Box
              fullwidth
              display="flex"
              justifyContent="center"
              marginBottom={2.5}
            >
              <Box maxWidth="sm" textAlign="left">
                <Typography variant="body2">
                  公司名稱 : 揚盛有限公司(REGENT CHAMPION COMPANY LIMITED)
                </Typography>
                <Typography variant="body2">統編: 83438598</Typography>
              </Box>
            </Box>
          </Grid>
          {aboutContent.map((item) => {
            return (
              <Typography variant="body1" style={{ marginBottom: "15px" }}>
                {item}
              </Typography>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Box mb={6} mt={2}>
            <AboutCarousel />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

// carousel

function AboutCarousel() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const isSmPhone = useMediaQuery("(max-width:380px)");
  const useStyles = makeStyles({
    swiperBox: {
      maxHeight: "500px",
      width: isTablet ? "100%" : "70%",
      "& .swiper-pagination-bullet": {
        width: "12px",
        height: "12px",
      },
      "& .swiper-pagination-bullet+.swiper-pagination-bullet": {
        marginLeft: "5px",
      },
      "& .swiper-pagination": {
        bottom: "0" + "!important",
        left: "50%" + "!important",
        transform: "translateX(-50%)" + "!important",
        width: "100%",
      },
      "& .swiper-button-prev:after": {
        fontSize: "24px",
        padding: "5px",
        color: isSmPhone ? "#fff" : "",
      },

      "& .swiper-button-next:after": {
        fontSize: "24px",
        padding: "5px",
        color: isSmPhone ? "#fff" : "",
      },
    },
  });
  const classes = useStyles();
  const slideImages = [
    "/assets/images/About/about (5).jpg",
    "/assets/images/About/about (6).jpg",
    "/assets/images/About/about (7).jpg",
    "/assets/images/About/about (8).jpg",
    "/assets/images/About/about (9).jpg",
    "/assets/images/About/about (10).jpg",
    "/assets/images/About/about (11).jpg",
    "/assets/images/About/about (12).jpg",
    "/assets/images/About/about (13).jpg",
    "/assets/images/About/about (14).jpg",
    "/assets/images/About/about (15).jpg",
    "/assets/images/About/about (16).jpg",
    "/assets/images/About/about (17).jpg",
    "/assets/images/About/about (18).jpg",
    "/assets/images/About/about (19).jpg",
    "/assets/images/About/about (20).jpg",
    "/assets/images/About/about (21).jpg",
    "/assets/images/About/about (22).jpg",
    "/assets/images/About/about (23).jpg",
    "/assets/images/About/about (24).jpg",
    "/assets/images/About/about (25).jpg",
    "/assets/images/About/about (26).jpg",
  ];
  return (
    <div>
      <Swiper
        id="swiper"
        slidesPerView={1}
        autoplay={true}
        loop
        navigation
        pagination={{ clickable: true }}
        className={classes.swiperBox}
      >
        {slideImages.map((item) => {
          return (
            <SwiperSlide>
              <div style={{ borderRadius: "30px" }}>
                <img
                  src={item}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "450px",
                  }}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
