import React from "react";
import { Typography, Grid, makeStyles, Box } from "@material-ui/core";

export default function ReturnExChangePage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "auto",
      backgroundColor: "white",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #FFB506",
        margin: "50px auto",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Grid
      className={classes.box}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={11}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">退換貨服務</Typography>
        </Box>
        <Typography variant="h6" style={{ marginBottom: "30px" }}>
          根據消費者保護法之規定，揚盛3C購物網站在台灣地區提供您享有商品到貨七天猶豫期隨時解約退貨之權益。*猶豫期非試用期，僅提供外觀、規格確認是否符合網站說明，商品一經拆封使用後，除非品質有異常不良，恕不接受退換貨*。
          <br />
          <br />
          如消費者堅持退貨退款(實際收貨商品與網頁標示內容皆符合，且商品功能正常，無品質異常不良，仍堅持退貨)，揚盛3C購物網站將交由原廠回收並判定該機況的整新復原費用
          (整新至倉庫寄出前尚未拆封新品狀態)，其人力/包材等處理成本，報價給消費者後(約該訂價之1成到3成不等，視原廠-供貨商報價為準)，由[原購買金額]扣除[整新復原費用]後，才進行退款，若特殊商品另議。
          <br />
          <br />
          特殊商品：
          個案處理請洽客服人員。為保障會員訂購交易安全，訂單成立後即無法變更商品數量、款式、顏色、尺寸，若您需變更訂單內容，需請您先取消訂單後重新訂購。
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          於商品到貨七天內(鑑賞期)符合下述條件，本網站無條件退貨：
        </Typography>
        <Typography variant="h6">
          ①商品宅配到府後發現，包裝內配件不齊全或商品規格與外包裝說明不符合。
          <br />
          ②商品宅配到府後發現，有瑕疵或於運送過程中造成損壞者。
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          下列情況將影響您的退貨權限，無法辦理退貨：
        </Typography>
        <Typography variant="h6">
          ■客製化/訂製化商品無法退貨
          <br />
          ■一般商品一經拆封或安裝使用，除商品本身有製造時產生瑕疵、損壞的情況外，恕不接受退換。
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          智慧財產權商品：書籍、軟體、遊戲、點數卡。
          <br />
          耗材商品：墨水、色帶、碳粉、紙類、磁片、MO片、任何光碟形式儲存之商品、CPU、記憶體、硬碟、
          印表機(已裝上墨水使用狀況)、記憶卡類及經剪裁之耗材。
          <br />
          <br />
        </Typography>
        <Typography variant="h6">
          商品包裝毀損、封條移除、吊牌拆除、貼膠移除或標籤拆除等情形。
          <br />
          代客訂購或特別附註聲明之商品。因使用不當或人為因素造成商品損壞
          （例如：商品本身或外包裝及說明書等如有刮傷、損毀、書寫文字髒污）其他逾越檢查之必要或可歸責於您之事由，致商品有毀損、滅失或變更者。
          <br />
          手機與筆記型電腦因首次開機，就會連網註冊該機碼與作業系統，一經註冊至原廠資料庫後，無法復原。
          <br />
          網頁商品會因為使用不同的品牌螢幕以及解析度不同，造成圖片顏色呈現略有不同，請以實品顏色為準，恕無法視為瑕疵。
          <br />
          ◆如遇商品損壞或瑕疵，申請退貨退款之流程示意圖〔商品退貨流程〕
          <br />
          <br />
        </Typography>
        <Typography variant="h6">
          ① 商品到貨，發現損壞、瑕疵
          <br />
          ▼<br />
          ②前往至會員專區&gt;&gt;訂單查詢&gt;&gt;申請退貨
          <br />
          ▼<br />
          ③透過 揚盛3C購物網站官方LINE＠或 寫信給客服，提供商品瑕疵圖文
          <br />
          ▼<br />
          ④宅配到府取貨，回收損壞/瑕疵商品
          <br />
          ▼<br />
          ⑤退貨商品審核（經原廠檢測流程7-14工作天）
          <br />
          ▼<br />
          ⑥收到退款
          <br />
          <br />
        </Typography>
        <Typography variant="h6">
          ■提醒您，收到瑕疵商品，提出申請退貨/換貨時，請告知退貨必要資訊(見下說明)，本公司於
          1～2 個工作天內本公司彙整申請資料提供原廠判斷進行退貨審核。
          <br />
          ■等待回覆審核同時，請務必將您所訂購之完整商品、配件、贈品及原包裝盒、原訂單發票置入於原外包裝箱內，勿直接在商品原廠外盒書寫文字或額外黏貼東西，並將商品回復一剛開始送達時的包裝原狀。若原廠包裝損毀將可能影響您的退貨權限。
          <br />
          ■通過申請審核後，將由本公司客服的留言系統或EMAIL
          或電話等管道通知您，並委託宅配公司於 3～5
          個工作天內電話連絡前往取回商品，敬請保持電話暢通，宅配取件後即提供簽收單據，請務必留存。
          (宅配公司為原廠委託之物流單為，僅做收件動作，商品將由原廠進行瑕疵檢測作業)。
          <br />
          ■
          以原廠的檢測判斷作業做最終退換貨的依據，原廠檢測作業約7-14個工作天（不含例假日）。宅配公司僅收件，商品將由原廠進行瑕疵檢測作業)。
          <br />
          ■放棄所有權
          當您的產品維修完成後或您不同意維修，揚盛3C有限公司將透過維修服務流程送回您的機器。如果您未取回您的產品，或按照您提供的地址無法送達，揚盛3C有限公司將在
          60
          天內保管您的產品。在此期限過後，揚盛3C有限公司將依據您申請維修服務時所提供的聯絡資料通知取件。如您仍未前來取回您的產品，揚盛3C有限公司保留包括向您索賠保管費用、按照適用的法律法規處理產品以及任何對於未支付費用的法律留置權等權利。
          <br />
          ★若因退貨商品不完整或配件遺漏，導致需再次派貨運公司需至客戶指定地點收貨情況，客戶需自行負擔此項額外之貨運處理費用。
          <br />
          ★不良瑕疵品退換經過檢查與測試之後，若發現商品本身並無瑕疵，消費者仍必須支付所有發生之相關費用。若發現收到瑕疵商品（商品缺件、外觀受損、功能異常等），請務必請立即留言、來電告知瑕疵狀況或用LINE聯繫。
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          〈退貨請提供以下資訊〉
        </Typography>
        <Typography variant="h6" style={{ marginBottom: "100px" }}>
          ● 訂單編號： <br />
          ● 訂購人姓名： <br />
          ● 瑕疵品取貨地址：（會派車取貨，請務必確認正確地址） <br />
          ● 聯絡人電話： <br />
          ● 退貨原因：請配合瑕疵照片，詳細交代 <br />
          ● 瑕疵商品照片：請拍下細部清楚多張照片一併附上 <br />
          請一定要附圖，協助揚盛3C購物網站與供貨廠商第一時間判斷，避免影響換貨作業與時效。{" "}
          <br />
        </Typography>
      </Grid>
    </Grid>
  );
}
