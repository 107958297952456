import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select as Select2,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "./Alert";
import Button from "./Button";
import { Select } from "./Form";
import LoadingModal from "./LoadingModal";
import divisions from "../divisions.json";

const Get_USER = gql`
  query user {
    user {
      id
      fullName
      mobile
    }
  }
`;

export default function BuyFloatingLayer({
  open,
  content,
  onChangeFloatingwindowClose = () => {},
}) {
  const Alert = useAlert();
  const useStylesTable = makeStyles((theme) => ({
    QuestionsContainer: {
      flexDirection: "column",
    },
  }));
  const [paymentMethod, setPaymentMethod] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverCellPhone, setReceiverCellPhone] = useState(null);
  const [division, setDivision] = useState("");
  const [subdivision, setSubdivision] = useState("");
  const [address, setAddress] = useState("");
  const [convenienceStore, setConvenienceStore] = useState("7-11");
  const [convenienceStoreText, setConvenienceStoreText] = useState("");
  const [shippingmethod, setShippingmethod] = useState("CONVENIENCE_STORE");
  const classesTable = useStylesTable();
  const divisionKey = Object.keys(divisions);
  const subdivisionsKey =
    division && Object.keys(divisions[division].subdivisions);
  const ShippingmethodArray = [
    { title: "超商取貨", value: "CONVENIENCE_STORE" },
    { title: "宅配", value: "HOME_DELIVERY" },
  ];
  const subdivisionRef = useRef("");
  const convenienceStoreArray = [
    {
      label: "7-11",
      value: "7-11",
    },
  ];

  const [getUser, { loading }] = useLazyQuery(Get_USER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ user }) {
      if (user) {
        setReceiverName(user.fullName);
        setReceiverCellPhone(user.mobile);
      }
    },
    onError(error) {
      Alert.notify(`${error}`);
    },
  });

  useEffect(() => {
    if (open) {
      getUser();
    } else {
      setPaymentMethod("");
      setDivision("");
      setSubdivision("");
      setAddress("");
      setConvenienceStoreText("");
      setShippingmethod("CONVENIENCE_STORE");
    }
  }, [open]);

  useEffect(() => {
    if (Boolean(subdivisionRef.current)) {
      setSubdivision(subdivisionRef.current);
    } else {
      setSubdivision("");
    }
  }, [division]);

  function initialization(value, paymentMethod, shipmentInput) {
    onChangeFloatingwindowClose(value, paymentMethod, shipmentInput);
  }

  function _createShipment() {
    if (!Boolean(paymentMethod)) {
      return Alert.alert("通知", "請選擇付款方式！", [
        {
          text: "確定",
        },
      ]);
    }
    if (!Boolean(receiverName)) {
      return Alert.notify("收件人姓名未填寫！");
    }

    if (!Boolean(receiverCellPhone)) {
      return Alert.notify("收件人手機名未填寫！");
    }

    if (Boolean(shippingmethod === "CONVENIENCE_STORE")) {
      if (!Boolean(convenienceStoreText)) {
        return Alert.notify("收件門市未填寫！");
      }
    }

    if (Boolean(shippingmethod === "HOME_DELIVERY")) {
      if (!Boolean(division)) {
        return Alert.notify("收件人縣市未選擇！");
      }

      if (!Boolean(subdivision)) {
        return Alert.notify("收件人地區未選擇！");
      }

      if (!Boolean(address)) {
        return Alert.notify("收件人地址未填寫！");
      }
    }

    if (shippingmethod === "CONVENIENCE_STORE") {
      initialization("mutation", paymentMethod, {
        recipient: receiverName,
        recipientPhone: receiverCellPhone,
        shipmentMethod: shippingmethod,
        convenienceStore: `${convenienceStore}${" "}${convenienceStoreText}`,
      });
    } else {
      initialization("mutation", paymentMethod, {
        recipient: receiverName,
        recipientPhone: receiverCellPhone,
        shipmentMethod: shippingmethod,
        district: division,
        subdistrict: subdivision,
        address,
      });
    }
  }

  return (
    <Dialog
      style={{ marginBottom: "5em" }}
      open={open}
      onClose={initialization}
      fullWidth
      disableScrollLock
    >
      <LoadingModal loading={false} />
      <DialogTitle>通知</DialogTitle>
      {loading ? (
        <Grid container item justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <DialogContent>
            <Grid container direction="column" spacing={1}>
              {content && (
                <Grid item>
                  <Typography color="primary">{content}</Typography>
                </Grid>
              )}
              <Grid item>
                <Typography color="primary">選擇付款方式：</Typography>
                <Select
                  items={[
                    {
                      label: "信用卡",
                      value: "Credit",
                    },
                    {
                      label: "ATM 櫃員機",
                      value: "ATM",
                    },
                  ]}
                  value={paymentMethod}
                  onChange={(v) => setPaymentMethod(v)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Typography color="primary">運送方式</Typography>
                <FormGroup row>
                  {ShippingmethodArray.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={item.value}
                          checked={Boolean(shippingmethod === item.value)}
                          onChange={() => setShippingmethod(item.value)}
                        />
                      }
                      label={item.title}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid
                container
                item
                spacing={1}
                classes={{ root: classesTable.QuestionsContainer }}
              >
                <Grid item>
                  <Typography color="primary">收件人姓名</Typography>
                  <TextField
                    value={receiverName}
                    onChange={(e) => setReceiverName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Typography color="primary">收件人手機</Typography>
                  <TextField
                    value={receiverCellPhone}
                    onChange={(e) => setReceiverCellPhone(e.target.value)}
                    fullWidth
                  />
                </Grid>
                {shippingmethod === "CONVENIENCE_STORE" ? (
                  <Grid item>
                    <Typography color="primary">收件門市</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={3}>
                        <Select2
                          value={convenienceStore}
                          fullWidth
                          onChange={(e) => setConvenienceStore(e.target.value)}
                          displayEmpty
                          disabled
                        >
                          {convenienceStoreArray.map((item) => (
                            <MenuItem value={item.value} key={item}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select2>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <TextField
                          placeholder={"請輸入門市 ex: xx門市"}
                          value={convenienceStoreText}
                          onChange={(e) =>
                            setConvenienceStoreText(e.target.value)
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography color="primary">收件人地址</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={3}>
                        <Select2
                          value={division}
                          fullWidth
                          onChange={(e) =>
                            setDivision(() => {
                              subdivisionRef.current = "";
                              return e.target.value;
                            })
                          }
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            縣市
                          </MenuItem>
                          {divisionKey.map((item) => (
                            <MenuItem value={item} key={item}>
                              {divisions[item].zhTW}
                            </MenuItem>
                          ))}
                        </Select2>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Select2
                          value={subdivision}
                          fullWidth
                          onChange={(e) => setSubdivision(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            地區
                          </MenuItem>
                          {Boolean(division) &&
                            subdivisionsKey.map((item) => (
                              <MenuItem key={item} value={item}>
                                {divisions[division].subdivisions[item].zhTW}
                              </MenuItem>
                            ))}
                        </Select2>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => _createShipment()}
                >
                  確定
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={() => initialization()}
                >
                  留在此頁
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
