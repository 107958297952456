import React, { useState, Fragment } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useTheme,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import {
  emailPattern,
  verifyPattern,
  passwordPattern,
  mobilePattern,
} from "../components/unit";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode($mobile: String!) {
    sendVerificationCode(mobile: $mobile)
  }
`;

const REGISTER = gql`
  mutation register($userInput: UserInput!) {
    register(userInput: $userInput) {
      id
      token
    }
  }
`;

export default function RegisterPage() {
  const Alert = useAlert();
  const isPhone = useMediaQuery("(max-width:500px)");
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "100vh",
      backgroundColor: "#000000",
    },
    input: {
      height: "40px",
      padding: "0px",
      marginBottom: "5px",
      backgroundColor: "white",
    },
    labeltext: {
      color: "white",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      username: "",
      password: "",
      repassword: "",
      dateOfBirth: null,
      mobile: "",
      verificationCode: "",
      privacy: false,
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [verifying, setVerifying] = useState(false);

  function _sendVerificationCode() {
    if (!getValues("mobile")) {
      return Alert.notify("手機號碼未填寫！");
    }
    if (!mobilePattern.test(getValues("mobile"))) {
      return Alert.notify("手機號碼格式錯誤！");
    }
    sendVerificationCode({
      variables: { mobile: getValues("mobile") },
    });
  }

  const [sendVerificationCode, { loading }] = useMutation(
    SEND_VERIFICATIONCODE,
    {
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode) {
          return Alert.notify("驗證碼發送成功！", () => setVerifying(true));
        } else {
          return Alert.notify("驗證碼發送失敗，請重新嘗試！");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  function _submit(data) {
    const userInput = {
      ...data,
    };
    delete userInput.privacy;
    delete userInput.repassword;
    register({ variables: { userInput } });
  }
  const [register, { loading: registerLoading }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register) {
        return Alert.notify("註冊成功！", () => history.replace("/log-in"));
      } else {
        return Alert.notify("註冊失敗，請重新嘗試！");
      }
    },
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  return (
    <Grid>
      <LoadingModal loading={loading || registerLoading} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "auto", backgroundColor: "#000000" }}
      >
        <Grid container item spacing={2} xs={11} sm={6} md={5}>
          <Grid
            item
            xs={12}
            style={{
              display: isPhone ? "none" : "flex",
              justifyContent: "center",
              margin: "50px 0px",
            }}
          >
            <LogoIcon style={{ width: "300px", height: "64px" }} />
          </Grid>
          <Grid
            item
            style={{ display: "flex", justifyContent: "center" }}
            xs={12}
          >
            <Typography
              variant="h4"
              style={{
                fontWeight: 700,
                color: "white",
                marginTop: isPhone ? "20px" : "0px",
              }}
              gutterBottom
            >
              註冊會員
            </Typography>
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>姓名</InputLabel>
            <Controller
              name="fullName"
              control={control}
              rules={{ required: "姓名為必填" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入姓名"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item alignItems="flex-start">
            <InputLabel className={classes.labeltext}>信箱</InputLabel>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "信箱為必填",
                pattern: {
                  value: emailPattern,
                  message: "信箱格式不正確！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入信箱"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>帳號</InputLabel>
            <Controller
              name="username"
              control={control}
              rules={{
                required: "請填寫帳號",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入帳號"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>密碼</InputLabel>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "密碼為必填",
                pattern: {
                  value: passwordPattern,
                  message: "密碼格式錯誤，需包含英文以及數字！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入密碼"
                  type={showPassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword((e) => !e)}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>再次輸入密碼</InputLabel>
            <Controller
              name="repassword"
              control={control}
              rules={{
                required: "未輸入密碼",
                validate: {
                  value: (value) =>
                    getValues("password") === value || "密碼不一致",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請再次輸入密碼"
                  type={showRePassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowRePassword((e) => !e)}
                        >
                          {showRePassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid container item>
            <InputLabel className={classes.labeltext}>生日</InputLabel>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{ required: "生日為必填" }}
              render={({ field: { ref, ...other }, fieldState: { error } }) => (
                <TextField
                  type="date"
                  {...other}
                  error={error}
                  maxDate={new Date()}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>電話</InputLabel>
            <Controller
              name="mobile"
              control={control}
              rules={{
                required: "電話為必填",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="請輸入電話"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{
                          height: 40,
                          width: "45%",
                          marginLeft: "0.5em",
                        }}
                        onClick={_sendVerificationCode}
                        disabled={error}
                      >
                        電話驗證
                      </Button>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item>
            <InputLabel className={classes.labeltext}>驗證碼</InputLabel>
            <Controller
              name="verificationCode"
              control={control}
              rules={{
                required: "驗證碼未輸入",
                pattern: {
                  value: verifyPattern,
                  message: "驗證碼格式不正確！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder={verifying ? "驗證碼" : "請先按下手機驗證"}
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  disabled={!verifying}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="privacy"
              control={control}
              rules={{ required: "請同意使用者條款" }}
              render={({ field, fieldState: { error } }) => (
                <Grid container>
                  <Grid item xs={12} container alignItems="center">
                    <Checkbox
                      {...field}
                      style={{
                        color: theme.palette.primary.main,
                      }}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <Typography
                      variant="body2"
                      style={{ letterSpacing: "1.2px", color: "white" }}
                    >
                      我已閱讀並同意
                    </Typography>
                    <PrivacyPolicy control={control} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      style={{ color: "#f44336", paddingLeft: "2.3em" }}
                    >
                      &nbsp;&nbsp;
                      {error?.message}
                      &nbsp;&nbsp;
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid container item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(_submit)}
              style={{ marginBottom: "50px" }}
              fullWidth
            >
              註冊
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function PrivacyPolicy({ control }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const useStyles = makeStyles({
    ul: {
      letterSpacing: "1px",
      fontSize: "0.9em",
      "& li": {
        marginTop: "0.5em",
        lineHeight: "1.2",
      },
      "& > ul": {
        listStyle: "disc",
        "& > ul": {
          listStyle: "decimal",
          // "& > ul": {
          //   listStyleType: "decimal",
          // },
        },
      },
    },
  });
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const contentData = [
    {
      label:
        "本會員服務條款所稱之「會員」，為依照本站所定之加入會員程序加入完成並通過認證者。",
      li: [
        {
          label:
            "當您使用本站服務時，即表示您同意及遵守本服務條款的規定事項及相關法律之規定。",
        },
        {
          label:
            "本站保留有審核加入會員資格之權利，另外已加入會員者，本站亦保留有解除其會員資格之權利。",
        },
        {
          label:
            "本會員服務條款之修訂，適用於所有會員，當本站修訂本服務條款時，將於本站上公告。",
        },
      ],
    },
    {
      label: "會員",
      li: [
        {
          label:
            "使用本站所提供之會員服務時，於加入會員時所登錄之帳號及密碼使用之。",
        },
        {
          label:
            "會員須善盡帳號及密碼的使用與管理之責任。對於使用該會員之帳號及密碼(無關於會員本身或其他人)利用本站服務所造成或衍生之所有行為及結果，會員須自行負擔全部責任。",
        },
        {
          label:
            "會員之帳號及密碼遺失，或發現無故遭第三者盜用時，應立即通知本站連絡掛失，因未即時通知，導致本站無法有效防止及修改時，所造成的所有損失，會員應自負全責。",
        },
        {
          label:
            "每次結束使用本服務，執行會員之登出並關閉視窗，以確保您的會員權益。",
        },
        {
          label:
            "盜用第三者會員之帳號及密碼，導致第三者或本公司遭其他第三人或行政機關之調查或追訴時，第三者會員或本公司有權向您請求損害賠償，包括但不限於訴訟費用、律師費及商譽損失等。",
        },
      ],
    },
    {
      label: "會員登錄資料",
      li: [
        {
          label: "會員登錄資料須提供您本人正確、最新及完整的資料。",
        },
        {
          label:
            "會員登錄資料不得有偽造、不實等之情事(EX如個人資料及信用卡資料)，一經發現本公司可拒絕其加入會員資格之權利。並得以暫停或終止其會員資格，若違反中華民國相關法律，亦將依法追究。",
        },
        {
          label:
            "會員基本資料(EX:住址，電話及其他登錄資料)有變更時，請不定期更新相關個人資料，確保其正確及完整性。若您提供的資料有錯誤或不符等現象，本網站有權暫停或終止您的帳號，並拒絕您繼續使用本服務。",
        },
        {
          label:
            "未經會員本人同意，本公司原則上不會將涉及個人隱私之資料開示給第三者，唯資料共用原則…等不在此限(請參閱本站「隱私權保護聲明」相關規定)。",
        },
        {
          label:
            "會員應妥善保管密碼，不可將密碼洩露或提供給他人知道或使用；以同一個會員身分證字號和密碼使用本服務所進行的所有行為，都將被認為是該會員本人和密碼持有人的行為。",
        },
        {
          label:
            " 會員如果發現或懷疑有第三人使用其會員身分證字號或密碼，應該立即通知本公司，採取必要的防範措施。但上述通知不得解釋為本公司對會員負有任何形式之賠償或補償之責任或義務。",
        },
      ],
    },
    {
      label: "使用行為",
      li: [
        {
          label:
            "您使用本服務之一切行為必須符合當地或國際相關法令規範；對於使用者的一切行為，您須自行負擔全部責任。",
        },
        {
          label:
            "您同意絕不為非法之目的或以非法方式使用本服務，與確實遵守中華民國相關法規及網際網路之國際慣例，並保證不得利用本服務從事侵害他人權益或違法之行為",
        },
        {
          label: "您於使用本站會員服務時應遵守以下限制：",
          li: [
            {
              label: "有損他人人格或商標權、著作權等智慧財產權或其他權利內容。",
            },
            {
              label: "使用違反公共秩序或善良風俗或其他不法之文字。",
            },
            {
              label: "強烈政治、宗教色彩的偏激言論。",
            },
            {
              label:
                "未經本公司許可，不得利用本服務或本網站所提供其他資源，包括但不限於圖文資料庫、編寫製作網頁之軟體等，從事任何商業交易行為，或招攬廣告商或贊助人。",
            },
            {
              label: "其他違反本站「會員服務條款」的內容。",
            },
          ],
        },
      ],
    },
  ];
  function getLi(item) {
    if (item.li) {
      return (
        <ul>
          {item.li.map((item2) => (
            <>
              <li>{item2.label}</li>
              {item2.li ? getLi(item2) : null}
            </>
          ))}
        </ul>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          color="primary"
          onClick={handleClickOpen}
          style={{ textAlign: "right" }}
        >
          網站服務條款及隱私政策
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>隱私權條款</DialogTitle>
        <DialogContent style={{ padding: "2em 2em 2em 3em" }}>
          {contentData.map((item, index) => (
            <DialogContentText key={index} className={classes.ul}>
              {item.label}
              {getLi(item)}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
